$primarySpinnerColor: #a9a9a9;
$secondarySpinnerColor: #fff;

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3em;
  background-color: $secondarySpinnerColor;
  z-index: 1000;
  border-radius: 3em;

  &__circle {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    background-color: $primarySpinnerColor;
    border-radius: 100%;
    animation: spin 1s infinite linear;

    &-inner,
    &-gradient {
      position: absolute;
      width: 42px;
      height: 42px;
      transform: translate(-50%, -50%);
    }

    &-inner {
      top: 50%;
      left: 50%;
      background-color: $secondarySpinnerColor;
      border-radius: 100%;
    }

    &-gradient {
      top: -24px;
      left: 6px;
      background-color: $primarySpinnerColor;
      background-image: -moz-linear-gradient(
        0deg,
        $primarySpinnerColor 0%,
        $secondarySpinnerColor 50%
      );
      background-image: -webkit-linear-gradient(
        0deg,
        $primarySpinnerColor 0%,
        $secondarySpinnerColor 50%
      );
      background-image: linear-gradient(
        0deg,
        $primarySpinnerColor 0%,
        $secondarySpinnerColor 50%
      );
      transform: rotate(-45deg);
    }
  }
}

.tableauErrorMessage {
  text-align: center;
  font-weight: 400;
  color: darkred;
}
