@import "@res/smart-ui-component-library/build/scss/style-utils.scss";

div.outer-modal {
  width: 50vw;
  min-width: 800px;
}

.insight-form-container {
  display: flex;
  flex-direction: column;

  textarea {
    min-height: 10em;
  }

  .action-buttons {
    display: flex;
    gap: 1em;
    justify-content: flex-end;
  }

  .workflow {
    border-style: none;
    padding: 0;
    margin: 0;

    .workflow-item {
      display: inline-block;

      @include FullerBold;
    }

    .workflow-item,
    .workflow-item ~ * {
      margin-bottom: 1rem;
    }
  }

  .comment-history-container {
    height: 200px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &.dark {
      background-color: var(--gray-dark);
    }

    &.light {
      background-color: var(--res-gray-light);
    }

    .comment-history-item {
      padding: 0.5em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      .comment-text {
        white-space: pre-line;

        margin: 0 0 0.5em;
      }

      .comment-metadata {
        font-size: smaller;
        font-weight: bolder;
        margin: 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
