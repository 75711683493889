.header-button {
  button {
    margin-left: 1em;
    border-style: none !important;
    box-shadow: none !important;
  }

  > span {
    vertical-align: bottom;
  }
}
.info-icon {
  margin-right: 0.5em;
}

.table-key-and-share-button-container {
  display: flex;

  :first-child {
    margin-left: auto;
  }

  > * {
    margin-right: 1em;
  }
}

.wide-filter-box {
  width: 140px;
}

.technology-icon {
  position: absolute;
  top: 6px;
}
