@import "node_modules/@res/smart-ui-component-library/build/scss/style-utils";

.nav-dropdown-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.dropdown {
  width: 225px;
  line-height: 1em;
}

.nav-link {
  margin-left: 1em;
  @include PearsonRegular;
}

.nav-bar-logo {
  width: 140px;
  height: 40px !important;
}
