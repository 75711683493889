.analysis-history-item {
  padding: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  .analysis-text {
    white-space: pre-line;

    margin: 0 0 0.5em;
    height: auto;
    overflow: hidden;
  }

  .analysis-text-wrapper {
    flex-grow: 1;
  }

  .analysis-text-toolbar {
    position: sticky;
    top: 0px;
    z-index: 1000;
  }

  .analysis-save-discard {
    position: sticky;
    top: 0.5em;
    z-index: 1000;
    height: 1em;
    // margin-top: 0.5em;
  }

  .loading-spinner {
    margin-left: auto;
  }

  .analysis-metadata {
    font-size: smaller;
    font-weight: bolder;
    margin: 0;
    display: flex;

    .res-icon {
      width: 0.75em;
      height: 0.75em;
    }

    .edit-button {
      margin-left: auto;
    }

    .delete-button {
      margin-left: 0.5em;
      padding-top: 0.2em;
    }
  }
}
