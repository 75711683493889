@import "../../../../node_modules/@res/smart-ui-component-library/build/scss/style-utils";

.counter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.counter-col {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    @include LaCourRegular();
    margin: 0;
  }

  h5 {
    @include OhlRegular();
    margin: 0;
  }
}
