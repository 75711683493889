// Functions come first
@import "../node_modules/@res/smart-ui-component-library/build/scss/style-utils.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f4;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #262a2c;
  }
}
