.about-card {
  margin-top: 1em;
  width: 100%;
  min-height: 0px !important;
}

.about-card-content {
  padding: var(--space-large);
}

.about-link {
  color: var(--res-orange);
}
