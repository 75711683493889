.stack {
  display: flex;
  align-self: stretch;
  gap: 0.3rem;

  &.vertical {
    flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  &.horizontal {
    flex-direction: row;
    align-items: center;
  }

  &.gap-1 {
    gap: 1rem;
  }

  &.gap-2 {
    gap: 1rem;
  }

  &.gap-3 {
    gap: 1rem;
  }
}
