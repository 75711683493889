.insight-input {
  margin-bottom: 0.75rem;
}

label {
  font-size: 15px;
}

.analysis-container {
  min-height: 250px;
  max-height: 800px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  overflow-y: scroll;

  &.dark {
    background-color: var(--gray-dark);
  }

  &.light {
    background-color: var(--res-gray-light);
  }
}

.disabled-input.dark input {
  background-color: var(--gray-dark-80);
  color: #fff;
  cursor: initial;
}

.recommendation {
  width: 100%;
}
