.paper-card {
  width: 100%;
  min-height: initial !important;
  margin-top: 1em;
  padding-top: 1em;
  padding-bottom: 1em;

  header {
    height: 0;
    padding: 0;
  }
}

.no-bottom-padding {
  padding-bottom: 0;
}

.no-top-padding {
  padding-top: 0;
}
