div.analysis-editor-toolbar {
  margin-bottom: 0;

  .rdw-link-modal {
    height: auto;
  }

  &.dark {
    background: var(--gray-dark-80);
    border: 0;

    .rdw-option-wrapper {
      background: var(--gray-dark-20);

      img {
        filter: invert(1);
      }
    }

    .rdw-dropdown-wrapper {
      background: var(--gray-dark-20);
    }

    .rdw-dropdown-optionwrapper {
      background: var(--gray-dark-20);
    }

    .rdw-dropdownoption-active {
      background: var(--gray-dark-60);
    }

    .rdw-link-modal {
      background: var(--gray-dark-80);

      .rdw-link-modal-input {
        background: var(--gray-dark);
        color: #fff;
      }
    }

    .rdw-emoji-modal {
      background: var(--gray-dark-80);
    }

    .rdw-image-modal {
      background: var(--gray-dark-80);

      .rdw-image-modal-url-input {
        background: var(--gray-dark);
        color: #fff;
      }

      .rdw-image-modal-size-input {
        background: var(--gray-dark);
        color: #fff;
      }
    }

    .rdw-colorpicker-modal {
      background: var(--gray-dark-80);
    }
  }
}

.analysis-editor {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-top: 0;

  .rdw-image-alignment-options-popup {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &.dark {
    background: var(--gray-dark);

    .rdw-image-alignment-options-popup {
      background: var(--gray-dark-80);

      .rdw-image-alignment-option {
        background: var(--gray-dark);
      }
    }
  }

  img {
    max-width: 500px;
    max-height: 500px;
  }
}
